<script setup>
import { ref, onMounted } from 'vue';
import {usePage} from "@inertiajs/vue3";

const props = defineProps({
    stripeKey: String // Accept stripeKey as a prop
});

const page = usePage();
const stripe = ref(null);
const cardElement = ref(null);
const cardErrors = ref('');
const isProcessing = ref(false);
const { user } = page.props.auth;
const { plan } = page.props;

// Function to handle form submission
const handlePayment = async () => {
    isProcessing.value = true;
    cardErrors.value = '';

    const { paymentMethod, error: paymentMethodError } = await stripe.value.createPaymentMethod({
        type: 'card',
        card: cardElement.value,
        billing_details: {
            name: user.name,
            email: user.email,
        },
    });

    if (paymentMethodError) {
        cardErrors.value = paymentMethodError.message;
        isProcessing.value = false;
        return;
    }

    try {
        const response = await axios.post('/stripe/subscribe', {
            payment_method_id: paymentMethod.id,
            plan: plan, // Pass the selected plan to the backend
        });

        const result = response.data;

        if (result.status === 'error') {
            cardErrors.value = result.message;
            isProcessing.value = false;
        } else {
            // Handle successful subscription creation
            window.location.href = "/confirmed";
        }
    } catch (error) {
        cardErrors.value = error.response?.data?.message || 'An error occurred';
        isProcessing.value = false;
    }
};

// Mount Stripe Elements on component mount
onMounted(() => {
    if (!props.stripeKey) {
        console.error("Stripe key is missing");
        return;
    }

    // Initialize Stripe with the provided stripeKey
    stripe.value = Stripe(props.stripeKey);

    if (!stripe.value) {
        console.error("Stripe failed to initialize");
        return;
    }

    // Check that stripe.value is initialized correctly
    const elements = stripe.value.elements();
    if (!elements) {
        console.error("Stripe elements could not be initialized");
        return;
    }

    // Mount the card element to the DOM
    cardElement.value = elements.create('card');
    cardElement.value.mount('#card-element');
});
</script>

<template>
    <div>
        <form @submit.prevent="handlePayment" id="payment-form">
            <div id="card-element"></div>

            <button :disabled="isProcessing" type="submit" class="mt-3 w-full p-3 bg-blue-500 text-white rounded-lg">
                {{ isProcessing ? 'Processing please wait...' : 'Complete Payment' }}
            </button>

            <div id="card-errors" role="alert" v-if="cardErrors">{{ cardErrors }}</div>
        </form>
    </div>
</template>

<style scoped>
#card-element {
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
}

#card-errors {
    color: #df1b41;
    font-size: 14px;
    margin-top: 10px;
}
</style>
