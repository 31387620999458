<script setup>
import {defineProps, ref, onMounted, onBeforeUnmount} from "vue";
import Button from '@/Components/Forms/InputButton.vue';
import { useForm, usePage } from "@inertiajs/vue3";
const page = usePage();
import axios from 'axios';
import { marketTagOptions } from "@/data/marketTagOptions.js";
import Card from "@/Components/Card.vue";
import Multiselect from "vue-multiselect";

const filterPanelOpen = ref(false);

const toggleFilterPanel = () => {
    filterPanelOpen.value = !filterPanelOpen.value;
}

const props = defineProps({
    type: {
        type: String,
        default: 'creator'
    },
    subTitle: {
        type: String,
    },
    maxFollowers: {
        type: Number,
        default: 0
    }
});

let maxFollowers = props.maxFollowers;

console.log('Max followers:', maxFollowers);

const form = useForm({
    professional_alias: null,
    first_name: null,
    last_name: null,
    location: null,
    email: null,
    market_tags: null,
    availability: null,
    platform: null,
    min_followers: 0,
    max_followers: maxFollowers,
});

// Track filters
const activeFilters = ref({}); // Placeholder for filters
const users = ref([]);
const totalPages = ref(12); // Total number of pages
const perPage = ref(12); // Track the current page
const total = ref(0); // Track the current page
const currentPage = ref(1); // Track the current page
const isLoadingMore = ref(false); // For infinite scroll loading state
const searchQuery = ref('');

const getType = () => {
    // this const will check the amount of users etc being passed into this and will toggle the plural.
    const plural = 1;

    return `${props.type}${plural > 1 ? 's' : ''}`
}

// Extract the search query from the URL
const getSearchQueryFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('search') || ''; // Return search query or an empty string
};

// Infinite scroll handler
const loadMoreUsers = async (initialLoad = false) => {
    if (currentPage.value < totalPages.value && !isLoadingMore.value) {
        isLoadingMore.value = true;
        let nextPage = 1;

        if (!initialLoad) {
            console.log('Loading more users...');

            nextPage = currentPage.value + 1;
        }

        try {
            const response = await axios.get(`/api/search`, {
                params: {
                    page: nextPage,
                    type: props.type,
                    search: searchQuery.value, // Include the search query
                    ...activeFilters.value // Pass filters as well
                }
            });

            const newUsers = response.data.data;

            if (Array.isArray(newUsers)) {
                newUsers.forEach(newUser => {
                    const userExists = users.value.find(user => user.id === newUser.id);
                    if (!userExists) {
                        users.value.push(newUser);
                    }
                });

                perPage.value = response.data.per_page; // Update perPage count
                total.value = response.data.total; // Update total users count
                currentPage.value = response.data.current_page;
                totalPages.value = response.data.last_page;
            }
        } catch (error) {
            console.error('Error loading more users:', error);
        } finally {
            isLoadingMore.value = false;  // Set it to false when loading completes
        }
    }
};

const applyFilters = async () => {
    // Combine form data with any other active filters
    const filters = {
        professional_alias: form.professional_alias,
        first_name: form.first_name,
        last_name: form.last_name,
        location: form.location,
        email: form.email,
        market_tags: form.market_tags,
        availability: form.availability,
        platform: form.platform,
        min_followers: form.min_followers,
        max_followers: form.max_followers,
        // You can add more filters from the form object as needed
    };

    // Set the active filters and reset pagination
    activeFilters.value = filters;
    currentPage.value = 1;  // Reset to the first page
    users.value = [];  // Clear the current users array for fresh filtered results

    try {
        // Make the API request with the filters and reset the page to 1
        const response = await axios.get(`/api/search`, {
            params: {
                ...filters,
                page: currentPage.value,
                type: props.type, // Include the type (e.g. 'creator')
                search: searchQuery.value // Ensure search query is passed if available
            }
        });

        // Update users and pagination info with the response
        users.value = response.data.data;
        currentPage.value = response.data.current_page;
        totalPages.value = response.data.last_page;
        total.value = response.data.total; // Update total users count
    } catch (error) {
        console.error('Error applying filters:', error);
    }
};

// Detect when the user scrolls to the bottom of the page
const handleScroll = () => {
    const scrollPosition = window.innerHeight + window.scrollY;
    const bottomPosition = document.documentElement.scrollHeight - 100; // More precise calculation

    // Trigger loadMoreUsers only when not loading and the user is near the bottom
    if (scrollPosition >= bottomPosition && !isLoadingMore.value) {
        loadMoreUsers();
    }
};

// Add and remove the scroll event listener
onMounted(() => {
    // On page load, get search query from URL
    const initialSearchQuery = getSearchQueryFromUrl();

    if (initialSearchQuery) {
        // Load users based on the search query in the URL
        loadUsersWithSearchQuery(initialSearchQuery);
    } else {
        // Otherwise, load initial users
        loadMoreUsers(true);
    }

    const debounce = (func, delay) => {
        let inDebounce;
        return function() {
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => func.apply(this, arguments), delay);
        };
    };

    window.addEventListener('scroll', debounce(handleScroll, 100));

    window.addEventListener('search-updated', (event) => {
        const searchQuery = event.detail; // The updated search query
        // Call your loadMoreUsers or search function with the new query
        loadUsersWithSearchQuery(searchQuery);
    });
});

const loadUsersWithSearchQuery = (query) => {
    console.log('Search query:', query);

    // Use the search query to dynamically load the results
    axios.get(`/api/search`, {
        params: {
            search: query,
            type: props.type,
            page: 1, // Reset to first page
        }
    }).then(response => {
        users.value = response.data.data;
        currentPage.value = response.data.current_page;
        totalPages.value = response.data.last_page;
    }).catch(error => {
        console.error('Error fetching users:', error);
    });
};

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
    <div class="container pt-6 mx-auto mb-28 md:mb-44 relative z-10">
        <h2 class="font-semibold text-6xl text-gray-800 dark:text-white leading-tight tracking-tighter">
            {{ type.charAt(0).toUpperCase() + type.slice(1) }}s
        </h2>

        <h3 class="text-white text-lg" v-if="subTitle">{{ subTitle }}</h3>
    </div>

    <div class="container mb-6 relative z-10">
        <div class="flex items-center justify-between mb-5 relative z-50">
            <h2 class="text-white font-light">Showing <span class="font-bold">{{ users.length }}</span> of <span class="font-bold">{{ total }}</span> {{ getType() }}</h2>

            <div class="md:grid md:grid-cols-2 md:w-full md:max-w-sm md:items-center md:gap-5 md:shrink-0">
                <Button class="ms-auto md:md-0" variant="secondary" @click="toggleFilterPanel" @click.prevent="toggleFilterPanel">
                    <span class="hidden md:inline" v-if="!filterPanelOpen">Show filters</span>
                    <span class="hidden md:inline" v-if="filterPanelOpen">Hide filters</span>
                    <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"></path>
                    </svg>
                </Button>
                <select class="hidden md:inline form-select cursor-pointer border rounded-[8px] border-white bg-transparent text-white w-full">
                    <option value="Followers">Followers</option>
                    <option value="Name">Name</option>
                    <option value="Newest">Newest</option>
                    <option value="Oldest">Oldest</option>
                </select>
            </div>
        </div>

        <div :class="[filterPanelOpen ? 'md:grid-cols-4' : 'md:grid-cols-3', 'grid gap-6']">
            <div class="flex flex-col gap-6 text-white col-span-1" v-if="filterPanelOpen">
                <div class="flex flex-col gap-3 text-white col-span-1">
                    <h6>Follower count</h6>
                    <div class="flex gap-3 justify-between items-center">
                        <select v-model="form.min_followers" label="Min" class="form-select cursor-pointer border rounded-[8px] border-white bg-transparent text-white w-full">
                            <option value="0" selected>0</option>
                        </select>
                        <select v-model="form.max_followers" label="Max" class="form-select cursor-pointer border rounded-[8px] border-white bg-transparent text-white w-full">
                            <option
                                v-for="option in maxFollowers"
                                :key="option"
                                :value="option">
                                {{ option.toLocaleString() }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-col gap-3 text-white col-span-1">
                    <h6>Markets</h6>
                    <div class="flex gap-3 justify-between items-center">
                        <multiselect
                            :show-labels="false"
                            :close-on-select="false"
                            :multiple="true"
                            :options="marketTagOptions"
                            track-by="id"
                            label="name"
                            :max="3"
                            v-model="form.market_tags"
                        />
                    </div>
                </div>
                <div class="flex flex-col gap-3 text-white col-span-1">
                    <h6>Availability</h6>
                    <div class="flex gap-3 justify-between items-center">
                        <select v-model="form.availability" label="Availability" class="form-select cursor-pointer border rounded-[8px] border-white bg-transparent text-white w-full">
                            <option value="1">Available</option>
                            <option value="0">Not Available</option>
                        </select>
                    </div>
                </div>
                <div class="flex flex-col gap-3 text-white col-span-1">
                    <h6>Platforms</h6>
                    <label for="youtube-filter">
                        <input type="checkbox" name="platform[]" v-model="form.platform" id="youtube-filter" value="youtube" class="h-4 w-4 form-checkbox">
                        YouTube
                    </label>
                    <label for="tiktok-filter">
                        <input type="checkbox" name="platform[]" v-model="form.platform" id="tiktok-filter" value="tiktok" class="h-4 w-4 form-checkbox">
                        TikTok
                    </label>
                    <label for="instagram-filter">
                        <input type="checkbox" name="platform[]" v-model="form.platform" id="instagram-filter" value="instagram" class="h-4 w-4 form-checkbox">
                        Instagram
                    </label>
                    <label for="facebook-filter">
                        <input type="checkbox" name="platform[]" v-model="form.platform" id="facebook-filter" value="facebook" class="h-4 w-4 form-checkbox">
                        Facebook
                    </label>
                </div>
                <div class="flex flex-col gap-3 text-white col-span-1">
                    <Button @click="applyFilters">
                        <span>Apply filters</span>
                        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"></path>
                        </svg>
                    </Button>
                </div>
            </div>
            <div class="rounded-[50px] shadow-[0px_39px_44px_-16px_rgba(0,0,0,0.25)] py-10 px-8 relative min-h-[28rem] overflow-hidden bg-[#171B1F]" v-if="users.length === 0">
            </div>
            <div class="grid md:grid-cols-subgrid gap-x-[20px] gap-y-[40px] md:col-span-3" v-if="users.length > 0">
                <Card :type="type" v-for="user in users" :key="user.id" :user="user" />
            </div>
        </div>

        <div v-if="isLoadingMore" class="flex justify-center">
            <span class="text-white">Loading more users...</span>
        </div>
    </div>
</template>
